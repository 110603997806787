import axios from "axios";
import { baseurl } from "../../baseurl";
import { useEffect, useState } from "react";
import React from "react";

const HomeSecTwoh = () => {
  const [ratingcount, setRatingcount] = useState(0);
  const [articlecount, setArticlecount] = useState(0);


  useEffect(() => {
   
    fetchUserData();
  
}, []);



const fetchUserData = async () => {

  
  try {
    const response = await axios.get(`${baseurl}/getRatingcounts`);
    setArticlecount(response.data.articlecounts);
    setRatingcount(response.data.ratingcounts);


 




   
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};
  const data = [
    {
      text: "Rating Reported",
      value: "10",
    },

    {
      text: "DMs Reported",
      value: "5",
    },

    {
      text: "Article Reported",
      value: "35",
    },
  ];

  return (
    <>
      <div className="dash--home--title--h">
        <h1>Reported Content</h1>
        <p>Please review reports from users that requires your action</p>
      </div>
      <div className="dash--sec--one--card--cont--h">
        {/* {data.map((item, index) => ( */}
          <div  className="dash--sec--one--card--h">
            <p>Rating Reported</p>
            <h1>{ratingcount}</h1>
          </div>

          <div  className="dash--sec--one--card--h">
            <p>Article Reported</p>
            <h1>{articlecount}</h1>
          </div>
        {/* ))} */}
      </div>
    </>
  );
};

export default HomeSecTwoh;
