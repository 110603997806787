import React from "react";

import kabc from '../img/kabc.png'
import office from '../img/office-logo.png'


const Experianceah = ()=>{


return(



    <>

<div class="About-main-card-1-ah">
                    <h2>Experiance</h2>
                    

                    <div class="Office-ah-box">
                        <div class="Office-ah-logo">
                            <img src={office} alt=""/>
                        </div>

                        <div class="Office-ah-span">
                            <h2>Office Name</h2>
                            <p>14 months</p>
                        </div>
                    </div>

                    <div class="Current-ah-job">

                        <div class="Current-ah-job-box">
                        <div class="Current-ah-job-card">
                            <span><h2>Job Title (Current)</h2>
                            <p>Fulltime</p></span>

                            <span>
                                <h3>Oct 2021  -  Apr 2022</h3>
                                <p>7 months</p>
                            </span>
                        </div>
                        <p>Pellentesque vel tristique ultrices habitasse feugiat nulla tempor quam. Et risus commodo natoque pulvinar eu, interdum. Viverra tortor hac sollicitudin dictum sit. Condimentum eget et commodo sapien porta felis amet pellentesque. Erat augue enim turpis risus urna.</p>
                    </div>

                    <div class="Current-ah-job-box">
                        <div class="Current-ah-job-card">
                            <span><h2>Job Title (Current)</h2>
                            <p>Fulltime</p></span>

                            <span>
                                <h3>Oct 2021  -  Apr 2022</h3>
                                <p>7 months</p>
                            </span>
                        </div>
                        <p>Pellentesque vel tristique ultrices habitasse feugiat nulla tempor quam. Et risus commodo natoque pulvinar eu, interdum. Viverra tortor hac sollicitudin dictum sit. Condimentum eget et commodo sapien porta felis amet pellentesque. Erat augue enim turpis risus urna.</p>
                    </div>
                </div>
                    
</div>

    </>
    
)




}



export default Experianceah